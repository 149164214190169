.calendar {
  position: absolute;
  left: 0;
  top: calc(100% + 0.25rem);
  transform-origin: 50% 0;
}

.wrapper {
  position: relative;
}

.wrapper input {
  cursor: pointer;
}
